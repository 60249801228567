import React from 'react';

function Footer() {
    return (
    <footer class="contain">
        <div class="footer-container">
            <p>Pitch. All Rights Reserved 2019.</p>
        </div>
    </footer>
    );
}

export default Footer;