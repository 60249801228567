import React from "react";
import leaf from "../media/leaf.svg"; 
function About() {
    return (
        <section className="about contain">
        <div className="about-container row">
           <div className="about-logo">
                <img src={leaf} alt=""/>
          </div>
           <div className="about-info col-c">
            <p id="about-title">ABOUT US</p>
            <p id="about-title-mid">We are Canadian<br/> technology enthusiasts</p>
            <p>Every project is a new adventure with a new piece of technology. We choose to do what we do because we love the 
                challenge of crafting the perfect solution to a problem. As a relatively young team we don’t let what we currently
                know and understand limit our ability to deliver a solution.</p>
           </div>
        </div>
    </section>
    )
}

export default About;