import React from 'react';
//Styles
import "./styles/index.css";
//Components
import Header from "./components/Header.js";
import About from "./components/About.js";
import Work from "./components/Work.js";
import Contact from "./components/Contact.js";
import Footer from "./components/Footer.js";

function App() {
  return (
    <div className="App">
      <Header/>
      <About />
      <Work />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
