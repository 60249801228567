import React from 'react';

function Contact() {
    return (
    <section id="contact" class="contact contain">
        <div class="contact-container">
            <p>CONTACT</p>
            <p>Email - hello@pitchdev.com</p>
            <p>Phone - 289 401 2781</p>
        </div>
    </section>
    );
}

export default Contact;