import React from "react";

function Header() {
    return (
        <header className="header col contain">
        <nav id="home" className="nav-bar row">
            <div className="nav-logo">
                <a href="">Pitch.</a>
            </div>
            <div className="nav-links">
                <a href="#home">Home</a>
                <a href="#work">Work</a>
                <a href="#contact">Contact</a>
            </div>
        </nav>
        <div className="title-container">
            <h1>We build applications<br/>that emphasize performance<br/> and beautiful design.</h1>
        </div>
    </header>
    )
}

export default Header;