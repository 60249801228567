import React from "react";
import WorkItem from "./WorkItem";
//Logos
import kazing from "../media/kazing.svg";
import safe from "../media/safe.svg";
import inTouchai from "../media/inTouch.svg";
import darwinian from "../media/darwinian.svg";

const work_data =[
    {
        name: "kazing",
        color: "white",
        tech: ["PHP", "HTML", "SCSS", "NODEJS"],
        link: "https://kaazing.com/",
        date: 2018,
        logo: kazing
    },
    {
        name: "safe",
        color: "blue",
        tech: ["PHP", "SCSS", "HTML", "NODEJS", "GSAP"],
        link: "http://safedentistryinc.com/",
        date: 2019,
        logo: safe
    },
    {
        name: "inTouchai",
        color: "blue",
        tech: ["PHP", "CSS", "HTML", "PAGE BUILDER"],
        link: "https://www.intouch.ai/",
        date: 2018,
        logo: inTouchai
    },
    {
        name: "darwinian",
        color: "white",
        tech: ["PHP", "SCSS", "HTML", "NODEJS"],
        link: "https://darwinian.com/",
        date: 2019,
        logo: darwinian
    }
]

function Work() {
    const workItems = work_data.map((work, index) => {
        return <WorkItem key={index} props={work} />
    })
    return (
    <section id="work" className="work contain">
        <div className="work-container">
            <div className="work-title">
                <p>OUR WORK</p>
            </div>
            <div className="work-cards row">
                {workItems}
            </div>
        </div>
    </section>
    );
}

export default Work;