import React from 'react';
import blueArrow from "../media/arrow-blue.svg";
import whiteArrow from "../media/arrow-white.svg";

function WorkItem(propsObj) {
    const { props } = propsObj;
    let arrow, containerClass, techDisplay, inTouchID;
    if (props.color == "blue") {
        containerClass = "work-card-container blue col";
        arrow = whiteArrow;
    } else {
        containerClass = "work-card-container grey col";
        arrow = blueArrow;
    }

    if (props.name == "inTouchai") {
        inTouchID = "inTouchai-logo";
    }

    techDisplay = props.tech.map((item, index) => {
        return <p key={index}>{item}</p>;
    });

    return (
        <div className={containerClass}>
            <div className="work-card-header row">
                <p>Website Development</p>
                <a href={props.link} className="card-arrow">
                    <img src={arrow} alt="" />                                                                
                </a>
            </div>
            <div className="work-company-logo">
                <img id={inTouchID} src={props.logo} alt="" />
            </div>
            <div className="work-tech-tags row">
                <div className="tech">
                    {techDisplay}
                </div>
                <div className="work-date"><p>{props.date}</p></div>
            </div>
         </div>
    );
}

export default WorkItem;